import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/Button"
import Img404 from "../../assets/images/common/404.svg"

const BarStyle = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top: 0;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    height: 100px;
    width: 300px;
  }
  #text {
    padding: 10px;
    text-align: center;
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <BarStyle>
      <img src={Img404} alt="404" />
      <div id="text">
        <h3>We’re sorry, the page you’re looking for does not exist</h3>
      </div>
      <Button to="/">Back to Home</Button>
    </BarStyle>
  </Layout>
)

export default NotFoundPage
